// Theme extension variables. See https://6.docs.plone.org/volto/addons/theme.html
@use 'nsw-design-system/src/main';
@use 'addonsThemeCustomizationsVariables';

@use 'edit';
@use 'view';

// Theme extension custom SCSS. See https://6.docs.plone.org/volto/addons/theme.html
@use 'addonsThemeCustomizationsMain';

// Code below is a reset for the public UI to ensure it looks correct
html {
  font-size: 100% !important;
}

body.public-ui {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-family: var(--nsw-font-family);
  font-weight: var(--nsw-font-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--nsw-text-dark);

  @media (min-width: 62rem) {
    & {
      font-size: var(--nsw-font-size-sm-desktop);
      line-height: var(--nsw-line-height-sm-desktop);
    }
  }
}
