@use 'nsw-design-system/src/main';

// Don't use flex container for the main body
#main {
  display: block;
}

// Make sure that all `ul` within content blocks are styled correctly
.nsw-content-block__copy ul {
  @extend .nsw-content-block__list;
}

// A fix for the content-block icon only supporting SVGs in the CSS
.nsw-content-block__icon {
  & img {
    max-width: 4rem;
    max-height: 4rem;
  }
}

mark {
  font-weight: bold;
  background-color: transparent;
}

.nsw-breadcrumbs {
  margin-top: initial;
  @extend .nsw-p-y-sm;
}

.nsw-multi-select__dropdown {
  z-index: 300;
}

// Hero component's extra-wide support
.nsw-hero-banner {
  &--extra-wide {
    @media (min-width: 48rem) {
      .nsw-hero-banner__content {
        width: 67%;
        max-width: 67%;
      }
      .nsw-hero-banner__box,
      .nsw-hero-banner__links {
        max-width: 23%;
      }
    }
  }
}

.nsw-p6-linked-field {
  @extend .nsw-callout;
  margin-block-start: 0.25rem;
}

// Columns block view hacks
.columns-block,
.columns-view {
  .nsw-media {
    margin-block: 0;
  }
}

.nsw-ds-footer__aoc {
  @extend .nsw-footer__lower;
  @extend .nsw-p-y-sm;

  background-color: unset;
}

// HTML block auto-resizing
.block.html iframe {
  border: 0;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}
