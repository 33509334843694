@use 'nsw-design-system/src/main';

// Ensure Volto toolbar is above the NSW main navigation. Object browser should go over these too.
#toolbar,
#sidebar {
  z-index: 300;
}
*:not(#sidebar) > .sidebar-container {
  z-index: 400;
}

#page-edit {
  // Reset button styling
  button {
    border: 0;
    padding: 0;
    background: 0;
    cursor: pointer;
  }

  // The custom object browser with an input above it
  .object-browser-with-input .ui.segments {
    margin-block: 0 !important;
  }

  // Blocks that use 'TextLine' or 'WYSIWYG' component
  .block {
    & *[data-slate-editor='true'],
    .DraftEditor-root {
      cursor: text;
    }
  }

  // Image picker widget
  .widget.image-picker-widget {
    margin-inline: auto;
    padding-block-end: 6px;
    cursor: pointer;

    & svg,
    img {
      cursor: pointer;
    }
  }

  .nsw-content-block__link {
    // @extend a;
    // Copy the anchor styling
    font-weight: var(--nsw-font-bold);
    text-decoration: underline;
    // Needed as the Volto css is causing the size to be incorrect
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }

  // If a volto-form-block is in edit mode, `inert` is added. Let's set the pointer back to normal  (
  .block.form .volto-subblocks-wrapper .field:has([inert]) {
    cursor: default !important;
  }
}

// Columns block editing fixes
.columns-block {
  .nsw-col .blocks-form > fieldset {
    min-inline-size: initial;
  }
}

body.cms-ui.has-toolbar.has-sidebar .ui.wrapper > * {
  display: inherit !important;
  width: initial !important;
}
